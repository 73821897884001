import {defineStore} from 'pinia';

import {ref} from 'vue';
import axios from 'axios'
import Cookies from "vue-cookies";
import { ElMessage } from 'element-plus'
import {authmenu, getUserInfo} from "@/api/user";
import router from '@/router';  // 确保导入了 router

export const useUserStore = defineStore('user', () => {
    const token = ref('');
    const userInfo = ref({})
    const nickname = ref('');
    const avatar = ref('');
    const roles = ref([]); // 用户角色编码集合 → 判断路由权限
    const perms = ref([]); // 用户权限编码集合 → 判断按钮权限
    const menus = ref([]);
    const allInfo = ref({})
    // 登录
    function login(loginForm) {
        return new Promise((resolve, reject) => {
            const baseUrlStr = process.env.VUE_APP_BASE_API + '/' + process.env.VUE_APP_BASE_VERSION;
            axios.post(baseUrlStr + '/user/login', loginForm)
                .then((res) => {
                    if(res.data.return_code == 0){
                        token.value = res.data.result.token;
                        userInfo.value = res.data.result.userinfo;
                        resolve(res.data.result)
                    }else {
                        ElMessage({
                            message: res.data.message,
                            type: 'error',
                            duration: 3 * 1000
                        })
                        resolve(res.data.result)
                    }
                })
                .catch((error) => {
                    ElMessage({
                        message: error,
                        type: 'error',
                        duration: 3 * 1000
                    })
                    console.error("登录请求错误:", error);
                    reject(error);
                });
        });
    }
    //
    function getMenus() {
        return new Promise((resolve, reject) => {
            const headers = { 'X-Token': token.value };
            authmenu('',headers).then((res) => {
                menus.value = res
                resolve(res);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    // 获取信息(用户昵称、头像、角色集合、权限集合)
    function getInfo() {
        return new Promise((resolve, reject) => {
            const headers = { 'X-Token': token.value };
            getUserInfo('',headers).then((info)=>{
                allInfo.value = info
                localStorage.setItem('user_info', JSON.stringify(info));
                resolve(info)
            }).catch((error) => {
                reject(error);
            });
        });
    }

    // 注销
    function logout() {
        return new Promise((resolve, reject) => {
            token.value = '';
            userInfo.value = {};
            nickname.value = '';
            avatar.value = '';
            roles.value = [];
            perms.value = [];
            menus.value = [];
            allInfo.value = {};
            Cookies.remove('user_token');
            localStorage.removeItem('user_info');
            router.push('/login');
            resolve();
        });
    }

    // 重置
    function resetToken() {
        token.value = '';
        nickname.value = '';
        avatar.value = '';
        roles.value = [];
        perms.value = [];
    }

    return {
        token,
        nickname,
        avatar,
        roles,
        perms,
        login,
        getMenus,
        allInfo,
        getInfo,
        logout,
        resetToken,
        menus
    };
}, {
    persist: true // 头部余额不更新不及时，因此关闭了存储持久化
});
