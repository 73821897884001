<template>
    <div class="recordList">
        <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column
                prop="created_time"
                label="日期"
                width="180">
            </el-table-column>
            <el-table-column
                prop="creator"
                label="姓名"
                width="180">
            </el-table-column>
            <el-table-column
                prop="operate"
                label="进度">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'order_verify',
    props:{
        logRow: Object,
    },
    data(){
        return {
            tableData: []
        }
    },

    mounted() {
        this.tableData = this.logRow
    },

    methods:{
        hideDialog() {
            this.$emit('hideLog')
        }
    }
}
</script>

<style  lang="less" scoped>
.recordList{
    max-height: 400px;
    overflow-y: auto;
}
</style>